<template>
  <div id="sales-reports-view" class="sales-reports-container">
    <div class="flex flex-col">

      <!-- BREADCRUMB -->
      <breadcrumb-base :breadcrumb="breadcrumb" title="RechargeSummaryReport">
        <template v-slot:right-end>
          <vs-button @click="viewAllReportData()"
                     class="mt-3 py-4 px-3 ml-4" color="primary">
            {{ viewAll ? $t('ShowLess') : $t('ViewAll') }}
          </vs-button>
        </template>
      </breadcrumb-base>

      <div class="vx-row">
        <div class="vx-col md:w-1/5 mt-4">
          <vx-card>

            <vs-button v-if="filterSelectData" class="mt-3 w-full py-4 px-3" color="primary"
                       @click="clearFilterData">{{$t('ClearAllFilters').toUpperCase()}}
            </vs-button>
            <div class="py-2"></div>

            <!--            From TO Date Filters -->
            <!--            <h4>{{ $t('FromDate') | title }}</h4>-->
            <div class="py-2"></div>
            <label style="font-size: 12px; font-weight: bold">{{ $t('FromDate') }}
              <span
                @click="startDate = null;endDate = null;filterChanged()"
                class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
            </label>
            <datepicker :placeholder="$t('FromDate')"
                        :disabled-dates="{from: new Date()}"
                        v-model="startDate" v-on:select="filterChanged"></datepicker>
            <div class="py-2"></div>
            <!--            <h4>{{ $t('ToDate') | title }}</h4>-->
            <div class="py-2"></div>
            <label style="font-size: 12px; font-weight: bold">{{ $t('ToDate') }}
              <span
                @click="startDate = null;endDate = null;filterChanged()"
                class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
            </label>
            <datepicker :placeholder="$t('ToDate')"
                        v-model="endDate" :disabled-dates="disabledDates"></datepicker>
            <div class="py-2"></div>

            <label style="font-size: 12px; font-weight: bold">{{ $t('TransactionMode') }}
              <span
                @click="selectedPaymentMode = null;filterChanged()"
                class="span-clear cursor-pointer">{{ $t("Clear") }}</span>
            </label>
            <vs-select v-model="selectedPaymentMode"
                       autocomplete
                       class="select-large sm:pb-6  w-full">
              <vs-select-item :key="index" :value="item" :text="item | title | uppercase"
                              v-for="(item,index) in paymentData"/>
            </vs-select>

            <div class="py-2"></div>

            <vs-button v-if="filterSelectData" class="mt-3 w-full py-4 px-3" color="primary"
                       @click="filterChanged">{{ $t('Filter').toUpperCase() }}
            </vs-button>
            <div class="py-2"></div>
            <!--            <vs-button v-if="filterSelectData" class="mt-3 w-full py-4 px-3" color="primary"-->
            <!--                       @click="clearFilterData">{{ $t('ClearAllFilters').toUpperCase() }}-->
            <!--            </vs-button>-->
          </vx-card>
        </div>
        <div class="vx-col vx-col md:w-4/5">
          <!--    Empty List State -->
          <transition name="fade" v-if="walletHistory.length === 0">
            <div class="h-screen flex w-full bg-img" >
              <div
                class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
                <!--          <img src="@/assets/images/pages/svg_blank.svg" :alt="$t('NoData')"-->
                <!--               class="mx-auto mb-4 max-w-full">-->
                <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{$t('NoData')}}</h1>
              </div>
            </div>
          </transition>


          <div>
            <table class="header-table" style="width: auto;" v-if="walletHistoryRaw">
              <thead>
              <th class="text-center">
                {{ $t('TotalAmount') }}
              </th>
              </thead>
              <tbody>
              <tr>
                <td class="text-center">
                  <span>{{ walletHistoryRaw.total_value | germanNumberFormat4 }}</span>
                </td>
              </tr>
              </tbody>
            </table>
            <table class="w-full px-3" v-show="walletHistory.length > 0">
              <thead class="justify-between">
              <tr class="">
                <th class="px-16 py-2">
                  <span class="text-gray-700">{{ $t('Date') }}</span>
                </th>
                <th class="px-16 py-2">
                  <span class="text-gray-700">{{ $t('TransactionMode') }}</span>
                </th>
                <th class="px-16 py-2">
                  <span class="text-gray-700">{{ $t('Amount') }}</span>
                </th>
                <th class="px-16 py-2">
                  <span class="text-gray-700">{{ $t('OldCredit') }}</span>
                </th>
                <th class="px-16 py-2">
                  <span class="text-gray-700">{{ $t('NewCredit') }}</span>
                </th>
                <th class="px-16 py-2">
                  <span class="text-gray-700">{{ $t('OldBalance') }}</span>
                </th>
                <th class="px-16 py-2">
                  <span class="text-gray-700">{{ $t('NewBalance') }}</span>
                </th>
                <th class="px-16 py-2">
                  <span class="text-gray-700">{{ $t('Remarks') }}</span>
                </th>
              </tr>
              </thead>
              <tbody class="">
              <tr class="bg-white shadow-lg my-5 rounded" :key="indextr" v-for="(tr, indextr) in walletHistory">
                <td>
                  <p class="font-medium" style="max-width: 100px; word-break: normal !important;">{{ tr.updated | dateFormat }}</p>
                </td>
                <td>
                  <p class="font-medium truncate">{{ tr.transaction_mode | title | uppercase }}</p>
                </td>
                <td>
                  <p class="font-medium truncate table-value-item" >{{ tr.value | germanNumberFormat }}</p>
                </td>
                <td>
                  <p class="font-medium truncate" >{{ tr.to_standing_credit | germanNumberFormat }}</p>
                </td>
                <td>
                  <p class="font-medium truncate" >{{ tr.to_credit | germanNumberFormat }}</p>
                </td>
                <td>
                  <p class="font-medium truncate" v-if="activeUserInfo.balance_type == 0">{{ (tr.to_standing_balance) | germanNumberFormat }}</p>
                  <p class="font-medium truncate"  v-if="activeUserInfo.balance_type == 1">{{ (tr.to_standing_balance - tr.to_standing_credit) | germanNumberFormat }}</p>
                </td>
                <td>
                  <p class="font-medium truncate" v-if="activeUserInfo.balance_type == 0">{{ (tr.to_balance) | germanNumberFormat }}</p>
                  <p class="font-medium truncate" v-if="activeUserInfo.balance_type == 1">{{ (tr.to_balance - tr.to_credit) | germanNumberFormat }}</p>
                </td>
                <td>
                  <p class="font-medium" style="max-width: 200px;">{{ tr.remarks }}</p>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>


    </div>

  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import moment from 'moment'
import VxModal from '@/layouts/components/custom/VxModal'

export default {
  name: 'RechargeSummaryReport',
  components: {
    Datepicker,
    VxModal,
  },
  data() {
    return {
      cPage: 1,
      errorFetching: false,
      breadcrumb: [
        { title: 'Home', i18n: 'Home', url: '/' },
        { title: 'Recharge Summary Report', i18n: 'RechargeSummaryReport', active: true },
      ],
      filterSelectData: {},
      startDate: new Date(),
      endDate: new Date(),
      selectedPaymentMode: null,
      viewAll: false,
    }
  },
  computed: {
    disabledDates() {
      if (!this.startDate) {
        return {
          from: new Date(),}
      }
      return {
        to: this.startDate,
        from: new Date(),
      }
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
    walletHistory() {
      return this.$store.state.customer.walletHistory
    },
    walletHistoryRaw() {
      return this.$store.state.customer.walletHistoryRaw
    },
    transactionTypesList() {
      if(this.walletHistoryRaw) return this.$store.state.customer.walletHistoryRaw.transaction_type;
      else return [];
    },
    vPage() {
      return this.$store.state.customer.vPage
    },
    vTotalPages() {
      return this.$store.state.customer.vTotalPages
    },
    paymentData() {
      let list = this.activeUserInfo.credit_transaction_type_modes;
      return list
        .sort(function (a, b) {
          return a < b ? -1 : 1;
        })
    },
  },
  methods: {

    getTransactionType(value) {
      if (value === 0) return 'Credit'
      if (value === 1) return 'Cash'
      return ''
    },
    listPageChanged(item) {
      this.fetchWalletHistory(this.cPage - 1)
    },

    viewAllReportData() {
      this.viewAll = !this.viewAll;

      this.filterChanged()
    },
    getFilters() {
      const filter = {
        'rs_report': 1
      }

      if (this.startDate) {
        filter.start_date = moment(this.startDate).format('yyyy-MM-DD')
      }
      if (this.endDate) {
        filter.end_date = moment(this.endDate).format('yyyy-MM-DD')
      }

      if (this.selectedPaymentMode) {
        filter.transaction_mode = this.selectedPaymentMode.toLowerCase()
      }
      if(this.viewAll) {
        filter['view_all'] = this.viewAll ? 1 : 0;
      }

      return filter
    },
    fetchWalletHistory(page = '0') {
      let payload  = this.getFilters();
      this.$vs.loading()
      this.$store.dispatch('customer/fetchCustomerWalletHistory', { page, payload })
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
        })
        .catch((error) => {
          console.error(error)
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    filterChanged() {
      this.fetchWalletHistory('0', this.getFilters())
    },
    clearFilterData() {
      this.filterSelectData = {}
      this.startDate = null
      this.endDate = null
      this.filterChanged()
    },
    clearFilter(key) {
      this.filterSelectData[key] = null
      this.filterChanged()
    },
  },
  created() {
    // this.fetchWalletHistory()
  },
}
</script>

<style lang="scss">
.header-table {
  width: auto;

  th {
    font-weight: normal;
    font-size: 14px;
    min-width: 200px;
  }

  td {
    font-weight: bold;
    font-size: 24px;
    color: rgba(var(--vs-primary), 1);
  }
}
.sales-reports-container {
  table {
    border-collapse:separate;
    border-spacing:0 15px;
    tr {
      th {
        text-align: center;
        padding: 10px 8px;
      }
      td {
        text-align: center;
        padding: 14px 12px;
      }
    }
  }
}
</style>
